<template>
  <nav>
    <v-app-bar 
    color="#035D2C" 
    density="compact" 
    dark 
    app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase">
        <!-- <span style="font-size: medium;">pharmacie</span> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <div v-if="connecter">
          <!-- <span @click="compte" style="cursor:pointer">Utilisateur: {{ nom }}</span> -->
          <v-icon  size="x-large" right @click="dialogConfirm = true">mdi-power</v-icon>
        </div>
        <div v-else>
          <v-btn text @click="logout">
            <v-icon left>mdi-account-circle</v-icon>Se connecter
          </v-btn>
        </div>
      </div>

      <!-- debut dialog -->
      <v-dialog v-model="dialogConfirm" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h6 brown--text">
            Confirmation
          </v-card-title>
          <v-card-text>Voulez-vous vraiment vous déconnecter?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="connexion">
              Se déconnecter
            </v-btn>
            <v-btn color="dark darken-1" text @click="dialogConfirm = false">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog -->
    </v-app-bar>

    <v-navigation-drawer 
    v-model="drawer" 
    dark 
    absolute
    temporary
    app 
    color="#035D2C"  class="no-scrollbar">
      <v-list-item>
          <v-list-item-avatar>
            <v-img src="/rotary.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ nom }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
          v-for="(link, index) in links" :key="link.text" :to="link.route" 
          class="py-3"
          :style="getListItemStyle(index)" style="background-color: transparent !important;" @click="activateLink(index)"
          >
            <v-list-item-icon>
              <v-icon :color="getIconColor(index)">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :class="getTextClasses(index)">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
      <!-- <v-list column align-center>
        <v-list-item v-for="(link, index) in links" :key="link.text" :to="link.route" active-class="border"
          :style="getListItemStyle(index)" style="background-color: transparent !important;" @click="activateLink(index)">
          <v-list-item-content class="text-center mb-4">

            <v-icon style="font-size: 45px" :color="getIconColor(index)">{{ link.icon }}</v-icon>
            <v-list-item-title class="text-center" :class="getTextClasses(index)">
              {{ link.text }}

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-navigation-drawer>




  </nav>
</template>

<script>
import Cookies from 'js-cookie';
import { db } from '@/firebase/init';
import { doc, collection, getDoc, updateDoc, addDoc, deleteDoc, arrayUnion } from 'firebase/firestore';
import Vue from 'vue'
export default {
  data: () => ({
    dataGlobal: '',
    connecter: null,
    drawer: true,
    activeLink: 0,
    linkPermissions: {},
    dialogConfirm: false,

    isShow: true,
    links: [
      { icon: 'mdi-view-dashboard', text: 'Dashboard', route: '/', is_true: true },
      // { icon: 'mdi-plus-circle-outline', text: 'New Product', route: '/new' },
      { icon: 'mdi-file', text: 'Fiche médicale individuelle', route: '/ficheIndividuel', is_true: false },
      { icon: 'mdi-tooth', text: 'Fiche soin dentaire', route: '/ficheSoinDentaire', is_true: false },
      // { icon: 'mdi-link-variant', text: 'Vente Associer', route: '/association', is_true: false },
      { icon: 'mdi-account-multiple', text: 'Liste Utilisateur', route: '/listeUsers', is_true: false },
      { icon: 'mdi-cog', text: 'Parmètres', route: '/lieuPage', is_true: false },
      { icon: 'mdi-information', text: 'A propos', route: '/about', is_true: true },
      // { icon: 'mdi-account-cog', text: 'Compte', route: '/compte', is_true: true }
    ],
    nom: '',
  }),

  created() {
    // this.getUser();
    // this.drawer = this.shouldShowDrawer();
  },

  computed: {
    shouldShowDrawers() {

      const currentRoute = this.$router.currentRoute;
      return currentRoute.path !== '/';
    },
  },
  // watch: {
  //   '$route'() {
  //     // Lorsque la route change, mettez à jour la condition
  //     this.drawer = this.shouldShowDrawer();
  //   },
  // },
  async mounted() {
    const lakanaID = Cookies.get('lakanaID');
    if (lakanaID) {
      const cleanedID = lakanaID.replace(/"/g, '');
      const userRef = doc(collection(db, 'utilisateur'), cleanedID);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (userData) {
          this.connecter = true;
          this.nom = userData.nom;
          this.isShow = userData.role == "Maitre" ? false : true
          this.links.forEach((value, keys) => {
            if (keys > 0) value.is_true = this.isShow;

          })
        } else {
          this.connecter = false;
        }
      }
    }
  },
  methods: {

    activateLink(index) {
      this.activeLink = index;
    },
    isActiveLink(index) {
      return this.activeLink === index;
    },
    getListItemStyle(index) {
      return {
        backgroundColor: this.isActiveLink(index) ? '#EEEEEE' : 'transparent',
        borderTopLeftRadius: this.isActiveLink(index) ? '0' : 'rgb(35, 112, 44)',
        borderBottomLeftRadius: this.isActiveLink(index) ? '0' : 'rgb(35, 112, 44)'
      };
    },
    getIconColor(index) {
      return this.isActiveLink(index) ? '#004D40' : 'white';
    },
    getTextClasses(index) {
      return {
        'color-green': this.isActiveLink(index),
        'color-white': !this.isActiveLink(index)
      };
    },
    compte() {
      if (this.$route.path !== `/compte`) {
        this.$router.push(`/compte`);
      }
    },
    logout() {
      Cookies.remove('lakanaID');
      // const auth = getAuth();
      // signOut(auth).then(() => {
      //   console.log("Utilisateur déconnecté");
      // }).catch((error) => {
      //   console.log(error.message);
      // });
      this.$router.push(`/login`);
      this.dialogConfirm = false;
    },
    connexion() {
      Cookies.remove('lakanaID');
      this.dialogConfirm = false;
      this.$router.push(`/login`);
    },
    ListeUsers() {
      this.$router.push(`/compte`);
    },
    async hasUserRole(role) {

      // Récupérer les données utilisateur depuis Firestore en utilisant l'ID d'authentification
      const userDocRef = doc(db, 'users', getCookies("lakanaID"));
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      this.dataGlobal = userDocSnapshot.data();

      // Vérifier si les données utilisateur existent et si le rôle correspond
      console.log(userData, role, userData && userData.role === role)
      return userData && userData.role === role;

      return false;
    },
    async shouldShowLink(link) {
      if (!link.role) {
        return true;
      } else if (link.role === 'admin') {
        const isAdmin = await this.hasUserRole('admin');
        return isAdmin;
      } else {

        return false;
      }
    },
  }
};
</script>

<style scoped>
nav .no-scrollbar {
  overflow: hidden !important;
  font-size: 5px !important;
}

.border {
  border-left: 0 solid rgb(35, 112, 44);
  border-right: 0 solid rgb(35, 112, 44);
}

.color-green {
  color: #004D40;
}

.color-white {
  color: white;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  /* font-size: inherit; */
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-list-item__title {
  align-self: center;
  font-size: 15px;
}

element.style {
  border-top-left-radius: 0em;
  border-bottom-left-radius: 0em;
}
</style>
