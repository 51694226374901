import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/ficheIndividuel',
    name: 'FicheIndividuel',
    component: () => import('../views/FicheMedicaleIndividuelle.vue')
  },
  {
    path: '/ficheSoinDentaire',
    name: 'FicheSoinDentaire',
    component: () => import('../views/FicheSoinDentaire.vue')
  },
  {
    path: '/compte',
    name: 'Compte',
    component: () => import('../views/Users/Compte.vue')
  },
  {
    path: '/lieuPage',
    name: 'Lieu',
    component: () => import('../views/LieuPage.vue')
  },
  {
    path: '/listeUsers',
    name: 'ListeUsers',
    component: () => import('../views/Users/ListeUsers.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Users/Login.vue')
  },
  {
    path: '/testLogin',
    name: 'testLogin',
    component: () => import('../views/Users/testLogin.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Users/Register.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
