<template>
  <body>
    <!-- <img class="wave" src="../../assets/img/wave-01.png"> -->
    <div class="container">
      <div class="img">
        <img src="../../assets/img/rotary1.png">
      </div>
      <div class="login-content">
        <v-form @submit.prevent="login">
          <img src="../../assets/img/lakana.png">
          <h2 class="title">AUTHENTIFIEZ-VOUS</h2>
          <div class="input-div one">
            <div class="i">
              <i class="fas fa-user"></i>
            </div>
            <div class="div">
              <v-text-field v-model="pseudo" name="pseudo" color="rgb(35, 112, 44)" label="Identifiant"
                placeholder="Entrer votre identifiant" append-icon="mdi-account" :rules="rules.textRequis" clearable
                outlined></v-text-field>
            </div>
          </div>
          <div class="input-div pass">
            <div class="i">
              <i class="fas fa-lock"></i>
            </div>
            <div class="div">
              <v-text-field v-model="password" name="password" color="rgb(35, 112, 44)" label="Mot de passe"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showpassword ? 'text' : 'password'"
                required placeholder="Entrer votre mots de passe" @click:append="showpassword = !showpassword"
                :rules="rules.textRequis" clearable outlined></v-text-field>
            </div>
          </div>
          <!-- <a href="#">Forgot Password?</a> -->
          <v-checkbox v-model="rememberMe" class="mt-6" label="Souvenir de moi"
            style="font-size:small !important; margin-left: 30px;" color="rgb(148, 6, 13)"></v-checkbox>
          <input type="submit" class="btn" value="Se connecter">
        </v-form>
      </div>
    </div>
  </body>
</template>

<script>
import { db } from '@/firebase/init';
import { doc, getDoc, query, collection, where, getDocs } from "firebase/firestore";
import Cookies from 'js-cookie';
import Vue from 'vue';


export default {
  data() {
    return {
      pseudo: '',
      password: '',
      showpassword: false,
      snackbar: false,
      rememberMe: false,
      ColorNotif: "",
      colorText: "",
      iconText: "",
      iconColor: "",
      text: ``,
      rules: {
        textRequis: [
          v => !!v || 'Champs réquis',
        ],
      },
    };
  },
  async created() {
    const lakana = Cookies.get('lakana');
    if (lakana) {
      const cleanedID = lakana.replace(/"/g, '');
      const userRef = doc(collection(db, 'utilisateur'), cleanedID);
      const userSnapshot = await getDoc(userRef);

      console.log("Utilisateur trouvé :", lakana);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        console.log("Utilisateur trouvé :", userData);
        console.log("ID du document de l'utilisateur :", lakana);

        this.pseudo = userData.pseudo;
        this.password = userData.password;
        this.rememberMe = true;
      } else {
        console.log("Aucun utilisateur trouvé.");
      }
    }

  },
  methods: {
    async login() {
      var that = this;
      try {
        if ((this.password === null) || (this.password === "") || (this.password === undefined)) {
          return;
        }

          const userQuery = query(collection(db, 'utilisateur'), where('pseudo', '==', this.pseudo), where('password', '==', this.password), where('role', '==', "Maitre"), where('role', '==', "Maitre"), where('etat_supp', '==', "0"), where('statut', '==', 'ACTIVE'));
          const userSnapshot = await getDocs(userQuery);
          // console.log("Utilisateur userSnapshot :", this.password);

          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            const documentId = userSnapshot.docs[0].id;
            console.log("Utilisateur trouvé :", userData);
            console.log("ID du document de l'utilisateur :", documentId);

            Cookies.set('lakanaID', JSON.stringify(documentId));
            if (this.rememberMe) { Cookies.set('lakana', JSON.stringify(documentId), { expires: 7 }); }

            that.ColorNotif = ('#97741a')
            that.snackbar = true;
            that.text = "Authentification réussi";
            that.colorText = ('white')
            that.iconText = "mdi-check-circle"
            that.iconColor = 'success'


            // setTimeout(() => {
            //   if (this.$route.path !== '/') {
            //     this.$router.push('/');
            //   } else {
            //     console.log("dans la même route Lesy eh");
            //   }
            // }, 5000);

            if (this.$route.path !== '/') {
              this.$router.push('/');
              // window.location.href = window.location.href;
            }
            else {
              console.log("dans la même route Lesy eh");
            }

            location.reload();


          } else {
            that.ColorNotif = ('red')
            that.snackbar = true;
            that.text = "Pseudo ou Mots de pass incorrect";
            that.colorText = ('white')
            that.iconText = "mdi-alert"
            that.iconColor = 'warning'
            console.error("Aucun utilisateur trouvé.");
          }


          // that.ColorNotif = ('#97741a')
          // that.snackbar = true;
          // that.text = "Authentification réussi";
          // that.colorText = ('white')
          // that.iconText = "mdi-check-circle"
          // that.iconColor = 'success'

          // setTimeout(() => {
          //   if (this.$route.path !== '/') {
          //     this.$router.push('/');
          //   } else {
          //     console.log("dans la même route Lesy eh");
          //   }
          // }, 5000);

      } catch (error) {
        that.ColorNotif = ('red')
        that.snackbar = true;
        that.text = error.message;
        that.colorText = ('white')
        that.iconText = "mdi-alert"
        that.iconColor = 'warning'
        console.error(error.message);
      }


    },
    BackToLogin() {
      this.$router.push(`/`);
    },
    BackToRegister() {
      this.$router.push(`/register`);
    },
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 56%;
}

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
}

.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* z-index: 2; */
}

.login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.img img {
  width: 500px;
}

.v-form {
  width: 360px;
}

.login-content img {
  height: 100px;
}

.login-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  /* border-bottom: 2px solid #d9d9d9; */
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: .3s;
}

.input-div>div {
  position: relative;
  height: 45px;
}

.input-div>div>h5 {
  position: absolute !important;
  left: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #999 !important;
  font-size: 18px !important;
  transition: .3s !important;
}

.input-div:before,
.input-div:after {
  content: '' !important;
  position: absolute !important;
  bottom: -2px !important;
  width: 0% !important;
  height: 2px !important;
  background-color: #38d39f !important;
  transition: .4s !important;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50% !important;
}

.input-div.focus>div>h5 {
  top: -5px !important;
  font-size: 15px !important;
}

.input-div.focus>.i>i {
  color: #38d39f;
}

.input-div>div>input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: 'poppins', sans-serif;
}

.input-div.pass {
  margin-bottom: 4px;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: .3s;
}

a:hover {
  color: #38d39f;
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: rgb(148, 6, 13);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  text-transform: none;
  margin: 1rem 0;
  cursor: pointer;
  transition: .5s;
  margin-left: 13px;
}

.btn:hover {
  background-position: right;
}


@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .v-form {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
  }
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>




