import { initializeApp,firebase } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 
import 'firebase/firestore';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDS0nYNMazGL4_NtoN2fYZkwtRLHXAuBTs",
  authDomain: "lakana-de-la-sante.firebaseapp.com",
  projectId: "lakana-de-la-sante",
  storageBucket: "lakana-de-la-sante.appspot.com",
  messagingSenderId: "1061351155753",
  appId: "1:1061351155753:web:d9d7ffc640580629b41438"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app); 
const db = getFirestore(app);
const storage = getStorage(app); 

export { auth, db, storage };
export default app;