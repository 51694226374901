<template>
  <v-app>
    <Navbar  v-if="shouldShowNavbar" ></Navbar>
    <v-main class=""  style="background-color: #E8E9EB;">
      <router-view></router-view>
    </v-main>
    <!-- <Footer/> -->
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'
export default {
  name: 'App',
  components: {
    Navbar,
    // Footer
  },
  data() {
    return {
      shouldShowNavbar: true,
    };
  },
  watch: {

    '$route'(to) {
      console.log("to:", to)
      this.shouldShowNavbar = to.path !== '/login';
      console.log("to:", this.shouldShowNavbar)

    },
  },
};
</script>
